import React from 'react';
import './button.css';
const WhiteButton = ({ Text, onClick }) => {
  return (
    <button onClick={onClick} id="modern_white_button">
      {Text}
    </button>
  );
};

export default WhiteButton;
