import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
export const ASSET_NAME = 'landing-page';

// ================ Action types ================ //

export const FETCH_POPULAR_REQUEST = 'app/LandingPage/FETCH_POPULAR_REQUEST';
export const FETCH_POPULAR_SUCCESS = 'app/LandingPage/FETCH_POPULAR_SUCCESS';
export const FETCH_POPULAR_ERROR = 'app/LandingPage/FETCH_POPULAR_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryInProgress: false,
  queryMostPopularError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_POPULAR_REQUEST:

      return {
        ...state,
        queryInProgress: true,
        queryPopularError: null,
        currentPageResultIds: [],
      }
    case FETCH_POPULAR_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_POPULAR_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryPopularError: payload
      };

    default:
      return state;
  }
}

export default landingPageReducer

// ================ Action creators ================ //

export const queryPopularRequest = queryParams => ({
  type: FETCH_POPULAR_REQUEST,
  payload: { queryParams },
});

export const queryPopularSuccess = response => ({
  type: FETCH_POPULAR_SUCCESS,
  payload: { data: response.data },
});

export const queryPopularError = e => ({
  type: FETCH_POPULAR_ERROR,
  error: true,
  payload: e,
});

export const queryListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryPopularRequest(queryParams));


  return sdk.listings
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryPopularSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryPopularError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => {

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const { lang } = params

  return queryListings({
    perPage: 10,
    sort: 'meta_lastMonthBookings',
    include: ['author', 'author.profileImage', 'images'],
    'fields.listing': [
      'title',
      'geolocation',
      'price',
      'publicData.locationContext',
      'publicData.listingType',
      'publicData.transactionProcessAlias',
      'publicData.unitType',
      lang ? `publicData.translations.${lang}` : 'publicData.translations',
      // These help rendering of 'purchase' listings,
      // when transitioning from search page to listing page
      'publicData.pickupEnabled',
      'publicData.shippingEnabled',
      'metadata.rating'
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.bio', 'profile.metadata.ofProviderRating'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`, 'variants.square-small', 'variants.square-small2x'],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
