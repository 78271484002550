import React, { useEffect, useState } from 'react';
import css from './FieldTextInputPhoneInput.module.css';
// import PhoneInput from 'react-phone-number-input';
// import './react-phone-number-input.css';
import './react-international-phone.css';

import { PhoneInput } from 'react-international-phone';
// import 'react-international-phone/style.css';

const FieldTextInputPhoneInput = inputProps => {
  const [defaultCountry, setDefaultCountry] = useState('es'); // Default country code is "ES"
  const [phoneValue, setPhoneValue] = useState(inputProps.value || null); // State for phone number

  useEffect(() => {
    // Only fetch the country code if there is no phone number value
    if (!inputProps.value) {
      fetch('https://get.geojs.io/v1/ip/geo.json')
        .then(response => response.json())
        .then(data => {
          if (data.country_code) {
            setDefaultCountry(data?.country_code?.toLowerCase()); // Update default country based on user location
            // setPhoneValue(data.country_code); // Set phoneValue to the country code if no initial value
          }
        })
        .catch(error => console.error('Error fetching country:', error));
    } else {
      setPhoneValue(inputProps.value); // Set phoneValue to existing value
    }
  }, [inputProps.value]);

  // Use the default country code if no phone value exists
  // const country = phoneValue ? inputProps.country : defaultCountry;

  return (
    <div className={css.container}>
      <PhoneInput className={css.input} {...inputProps} defaultCountry={defaultCountry} />
    </div>
  );
};

export default FieldTextInputPhoneInput;

// import React from 'react';
// import PhoneInput from 'react-phone-number-input';
// import './react-phone-number-input.css';
// const FieldTextInputPhoneInput = inputProps => {
//   console.log('inputProps: ', inputProps);
//   return <PhoneInput {...inputProps} country="ES" />;
// };
// export default FieldTextInputPhoneInput;
