import React, { useEffect, useState } from 'react';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { propTypes } from '../../util/types';
import data from './data.json';
import css from './FAQsPage.module.css';
import StaticPage from '../PageBuilder/StaticPage';
import { LayoutComposer } from '../PageBuilder/PageBuilder';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import TopbarSearchForm from '../../components/Topbar/TopbarSearchForm/TopbarSearchForm';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

import { H2, } from '../../components';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import CookieMananger from '../../components/ui-components/cookies/CookieMananger';
import AccordionContainers from '../../components/Accordion/AccordionContainer';
import SmallBlackColorText from '../../components/text/SmallBlackColorText';
import { useParams } from 'react-router-dom';

export const FAQsPageComponent = props => {
  const [showNav, setShowNav] = useState(typeof window !== 'undefined' && !(window.scrollY == 0));

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const maxScroll = 18; // Ajusta esta cantidad según tu preferencia
      const shouldShowNav = scrollTop > maxScroll;

      setShowNav(shouldShowNav);
    };

    if (!window.scrollEventListener) {
      window.addEventListener('scroll', handleScroll);
      window.scrollEventListener = handleScroll;
    }

    return () => {
      if (window.scrollEventListener) {
        window.removeEventListener('scroll', window.scrollEventListener);
        window.scrollEventListener = null;
      }
    };
  }, []);

  const intl = useIntl();

  const layoutAreas = `
    topbar
    main
    footer
  `;

  const params = useParams();
  const lang = params?.lang;

  return (
    <StaticPage>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header">
                <TopbarContainer
                  className={css.topbar}
                  desktopClassName={!showNav ? css.topbarDesktop : null}
                  hideSearchForm
                />
              </Topbar>

              <main className={css.main}>
                <div className={css.page_title_data}>
                  <H2 className={css.title}>
                    {intl.formatMessage({ id: 'FAQsPage.FrequentlyAskedQuestionsTitle' })}
                  </H2>
                  {/* <SmallBlackColorText style={{ textAlign: 'center' }}>
                    <FormattedMessage
                      id="
                  Preguntaste, escuchamos y así ha surgido la nueva página Web. Hemos hecho que sea
                  más fácil para ti planificar viajes, aportar contenido y encontrar exactamente lo
                  que buscas."
                    />
                  </SmallBlackColorText> */}
                </div>
                <div className={css.main_page_content_containe}>
                  <div className={css.container_AccordionContainer}>
                    <AccordionContainers data={data[lang]} />
                  </div>
                  {/* <div className={css.imageFAQS}></div> */}
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </main>
              <Footer as="footer">
                <FooterComponent />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
      <CookieMananger />
    </StaticPage>
  );
};

FAQsPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { currentPageResultIds, queryInProgress, queryPopularError } = state.LandingPage;
  const mostPopularListings = getListingsById(state, currentPageResultIds);
  const { currentUser } = state.user;
  return {
    mostPopularListings,
    queryInProgress,
    queryPopularError,
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateFavorites: payload => dispatch(updateProfile(payload)),
});

const FAQsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FAQsPageComponent);

export default FAQsPage;
