const messagesEn = require('./en.json');
const messagesEs = require('./es.json');
const messagesEu = require('./eu.json')

const messagesInLocale = {
    es: messagesEs,
    en: messagesEn,
    eu: messagesEu
};

const defaultLocale = 'en'

const locales = ['es', 'en', 'eu']

const supportedMapboxLocales = { es: 'es', en: 'en', eu: 'es' }

module.exports = {
    defaultLocale,
    locales,
    messagesInLocale,
    supportedMapboxLocales,
    getPreferredLanguage: (navigator) => {
        const userLanguages = navigator.languages || [navigator.language || navigator.userLanguage];

        const normalizedLanguages = userLanguages.map(lang =>
            lang.toLowerCase().replace(/[-_]/g, '')
        );

        const preferredLanguage = normalizedLanguages.find(lang =>
            locales.includes(lang)
        );

        return preferredLanguage || defaultLocale;
    },
    langMiddleware: (req, res, next) => {
        const requestedLocale = req.url.split('/')[1];

        if (!locales.includes(requestedLocale)) {
            const clientLanguages = req.acceptsLanguages(locales) || defaultLocale;
            //const matchingLocale = clientLanguages.find((lang) => locales.includes(lang.split('-')[0]))?.split('-')[0] || defaultLocale;
            res.redirect(`/${clientLanguages}${req.url}`)
        } else {
            next();
        }


    }
}