import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Button,
  IconAdd,
  NotificationBadge,
} from '../../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import {
  AccountSettingsIcon,
  BusinessIcon,
  FavoritesIcon,
  MyEquipmentIcon,
  NotificationsIcon,
  ProfileSettingsIcon,
} from '../TopbarSearchForm/TopbarIcons';
import MenuItems from './MenuItems';
import { fetchStripeAccount } from '../../../ducks/stripeConnectAccount.duck';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    hideSearchForm,
    languageMenu,
    informationPagesMenu,
    stripeAccount,
    fetchStripeAccount,
  } = props;
  const [mounted, setMounted] = useState(false);
  const stripeAccountData = stripeAccount?.attributes?.stripeAccountData;
  const payouts_enabled = stripeAccountData?.payouts_enabled;

  useEffect(() => {
    try {
      if (stripeAccount !== null) {
        fetchStripeAccount();
      }
    } catch (err) {}

    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = hideSearchForm ? null : (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? <MenuItems {...props} /> : null;
  // const profileMenu = authenticatedOnClientSide ? (
  //   <Menu>
  //     <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
  //       <Avatar className={css.avatar} user={currentUser} disableProfileLink />
  //       {notificationDot}
  //     </MenuLabel>
  //     <MenuContent className={css.profileMenuContent}>
  //       <MenuItem key="InboxPage">
  //         <NamedLink
  //           className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
  //           name="InboxPage"
  //           params={{ tab: 'all' }}
  //         >
  //           <NotificationsIcon className={css.profileMenuIcon} />
  //           <FormattedMessage id="TopbarDesktop.notificationsLink" />
  //           {notificationCountBadge}
  //         </NamedLink>
  //       </MenuItem>
  //       <MenuItem key="ManageListingsPage">
  //         <NamedLink
  //           className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
  //           name="ManageListingsPage"
  //         >
  //           <MyEquipmentIcon className={css.profileMenuIcon} />
  //           <FormattedMessage id="TopbarDesktop.yourListingsLink" />
  //         </NamedLink>
  //       </MenuItem>
  //       <MenuItem key="FavoriteListingsPage">
  //         <NamedLink
  //           className={classNames(css.yourListingsLink, currentPageClass('FavoriteListingsPage'))}
  //           name="FavoriteListingsPage"
  //         >
  //           <FavoritesIcon className={css.profileMenuIcon} />
  //           <FormattedMessage id="TopbarDesktop.favoriteListingsLink" />
  //         </NamedLink>
  //       </MenuItem>
  //       <MenuItem key="ProfileSettingsPage">
  //         <NamedLink
  //           className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
  //           name="ProfileSettingsPage"
  //         >
  //           <ProfileSettingsIcon className={css.profileMenuIcon} />
  //           <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
  //         </NamedLink>
  //       </MenuItem>
  //       <MenuItem key="AccountSettingsPage">
  //         <NamedLink
  //           className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
  //           name="AccountSettingsPage"
  //         >
  //           <AccountSettingsIcon className={css.profileMenuIcon} />
  //           <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
  //         </NamedLink>
  //       </MenuItem>
  //       {payouts_enabled && (
  //         <MenuItem key="MyBusinessPage">
  //           <NamedLink
  //             className={classNames(css.yourListingsLink, currentPageClass('MyBusinessPage'))}
  //             name="MyBusinessPage"
  //           >
  //             <BusinessIcon className={css.profileMenuIcon} />
  //             <FormattedMessage id="TopbarDesktop.accountMyBusinessLink" />
  //           </NamedLink>
  //         </MenuItem>
  //       )}

  //       <MenuItem key="logout">
  //         <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
  //           <FormattedMessage id="TopbarDesktop.logout" />
  //         </InlineTextButton>
  //       </MenuItem>
  //     </MenuContent>
  //   </Menu>
  // ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {/* {search} */}
      <div className={css.rightActions}>
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            <IconAdd className={css.iconAdd} />
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
        {languageMenu}
        {informationPagesMenu}
        {profileMenu}
        {signupLink}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

const mapStateToProps = state => {
  const { stripeAccount } = state.stripeConnectAccount;
  return {
    stripeAccount: stripeAccount,
  };
};

// export default TopbarDesktop;

const mapDispatchToProps = dispatch => ({
  fetchStripeAccount: values => dispatch(fetchStripeAccount(values)),
});

const TopbarDesktopComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TopbarDesktop);

export default TopbarDesktopComponent;
