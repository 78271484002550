import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFilledFavorite.module.css';

const IconFilledFavorite = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <svg
            className={classes}
            viewBox="0 0 18 15"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="15"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 2.34405C7.26596 0.792614 5.32422 0.575586 3.75057 1.2949C2.08853 2.05462 0.9375 3.81871 0.9375 5.85276C0.9375 7.85188 1.77038 9.377 2.86254 10.6068C3.73715 11.5916 4.80766 12.4159 5.75312 13.1439C5.96744 13.3089 6.17534 13.469 6.37306 13.6249C6.75724 13.9277 7.16966 14.2507 7.5876 14.4949C8.00543 14.739 8.4822 14.9374 9 14.9374C9.5178 14.9374 9.99457 14.739 10.4124 14.4949C10.8304 14.2507 11.2427 13.9277 11.6269 13.6249C11.8246 13.469 12.0326 13.3089 12.2469 13.1439C13.1924 12.4159 14.2628 11.5916 15.1375 10.6068C16.2296 9.377 17.0625 7.85188 17.0625 5.85276C17.0625 3.81871 15.9115 2.05462 14.2494 1.2949C12.6758 0.575586 10.7341 0.792614 9 2.34405Z"
                ></path>
            </g>
        </svg>
    );
};

const { string } = PropTypes;

IconFilledFavorite.defaultProps = {
    className: null,
    rootClassName: null,
};

IconFilledFavorite.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconFilledFavorite;
