import React from 'react';
import './WhatsAppButton.css'; // Import the CSS for styling
import { PiWhatsappLogoLight } from 'react-icons/pi';

const WhatsAppButton = () => {
  const phoneNumber = '+34688681253'; // Replace with your phone number
  const defaultMessage = 'Hello, I would like to know more about your services.';

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(defaultMessage)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      {/* <img
        src="https://image.shutterstock.com/image-vector/social-media-icon-design-template-260nw-1905087507.jpg" // Replace with the WhatsApp icon URL
        alt="WhatsApp"
        className="whatsapp-icon"
      /> */}
      <PiWhatsappLogoLight className="whatsapp-icon" />
    </div>
  );
};

export default WhatsAppButton;
