import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { locales } from '../../translations/translations';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

export const loadData = (params, search) => dispatch => {
  const localizedTOSName = locales.includes(params.lang) ? `${TOS_ASSET_NAME}-${params.lang}` : TOS_ASSET_NAME
  const localizedPPName = locales.includes(params.lang) ? `${PRIVACY_POLICY_ASSET_NAME}-${params.lang}` : PRIVACY_POLICY_ASSET_NAME

  const pageAsset = {
    termsOfService: `content/pages/${localizedTOSName}.json`,
    privacyPolicy: `content/pages/${localizedPPName}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};
