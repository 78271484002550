import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { LINE_ITEM_NIGHT, DATE_TYPE_DATE, LINE_ITEM_HOUR, propTypes } from '../../util/types';
import { subtractTime } from '../../util/dates';

import css from './OrderBreakdown.module.css';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone } = props;
  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const result = calculateDaysAndHours(props);

  return (
    <>
      {/* <div style={{ width: '100%' }}>{JSON.stringify(result)}</div> */}

      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSectionLeft}>
          <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingStart" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          {/* <p>days: {result.days} --- hours: {result.hours}</p> */}
          <div className={css.itemLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>

        <div className={css.bookingPeriodSectionRight}>
          <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingEnd" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, code, dateType, timeZone } = props;

  if (!booking) {
    return null;
  }
  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const isNightly = code === LINE_ITEM_NIGHT;
  const isHour = code === LINE_ITEM_HOUR;
  // const endDay = isNightly || isHour ? localEndDateRaw : subtractTime(localEndDateRaw, 1, 'days');
  const endDay = localEndDateRaw;
  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          startDate={localStartDate}
          endDate={endDay}
          dateType={dateType}
          timeZone={timeZone}
        />
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { booking: null, dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;

const input = {
  startDate: '2024-10-25T22:00:00.000Z',
  endDate: '2024-10-27T22:00:00.000Z',
  dateType: 'datetime',
  timeZone: 'Europe/Madrid',
};

// Function to calculate the difference
export function calculateDaysAndHours(input) {
  const { startDate, endDate } = input;

  // Check if both dates are defined
  if (!startDate || !endDate) {
    console.log('Either startDate or endDate is undefined');
    return;
  }

  // Convert dates from ISO string to JavaScript Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the time difference in milliseconds
  const diffInMs = end - start;

  // Convert milliseconds to days and hours
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // total full days
  const remainingMs = diffInMs % (1000 * 60 * 60 * 24); // remainder in ms after extracting days
  const diffInHours = Math.floor(remainingMs / (1000 * 60 * 60)); // full hours left

  // Return the result
  return {
    days: diffInDays,
    hours: diffInHours,
  };
}

// Calculate days and hours between the given dates
