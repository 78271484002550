export const countries3To2 = {
    AFG: "AF",
    ALB: "AL",
    DZA: "DZ",
    ASM: "AS",
    AND: "AD",
    AGO: "AO",
    AIA: "AI",
    ATA: "AQ",
    ATG: "AG",
    ARG: "AR",
    ARM: "AM",
    ABW: "AW",
    AUS: "AU",
    AUT: "AT",
    AZE: "AZ",
    BHS: "BS",
    BHR: "BH",
    BGD: "BD",
    BRB: "BB",
    BLR: "BY",
    BEL: "BE",
    BLZ: "BZ",
    BEN: "BJ",
    BMU: "BM",
    BTN: "BT",
    BOL: "BO",
    BES: "BQ",
    BIH: "BA",
    BWA: "BW",
    BVT: "BV",
    BRA: "BR",
    IOT: "IO",
    BRN: "BN",
    BGR: "BG",
    BFA: "BF",
    BDI: "BI",
    CPV: "CV",
    KHM: "KH",
    CMR: "CM",
    CAN: "CA",
    CYM: "KY",
    CAF: "CF",
    TCD: "TD",
    CHL: "CL",
    CHN: "CN",
    CXR: "CX",
    CCK: "CC",
    COL: "CO",
    COM: "KM",
    COD: "CD",
    COG: "CG",
    COK: "CK",
    CRI: "CR",
    HRV: "HR",
    CUB: "CU",
    CUW: "CW",
    CYP: "CY",
    CZE: "CZ",
    CIV: "CI",
    DNK: "DK",
    DJI: "DJ",
    DMA: "DM",
    DOM: "DO",
    ECU: "EC",
    EGY: "EG",
    SLV: "SV",
    GNQ: "GQ",
    ERI: "ER",
    EST: "EE",
    SWZ: "SZ",
    ETH: "ET",
    FLK: "FK",
    FRO: "FO",
    FJI: "FJ",
    FIN: "FI",
    FRA: "FR",
    GUF: "GF",
    PYF: "PF",
    ATF: "TF",
    GAB: "GA",
    GMB: "GM",
    GEO: "GE",
    DEU: "DE",
    GHA: "GH",
    GIB: "GI",
    GRC: "GR",
    GRL: "GL",
    GRD: "GD",
    GLP: "GP",
    GUM: "GU",
    GTM: "GT",
    GGY: "GG",
    GIN: "GN",
    GNB: "GW",
    GUY: "GY",
    HTI: "HT",
    HMD: "HM",
    VAT: "VA",
    HND: "HN",
    HKG: "HK",
    HUN: "HU",
    ISL: "IS",
    IND: "IN",
    IDN: "ID",
    IRN: "IR",
    IRQ: "IQ",
    IRL: "IE",
    IMN: "IM",
    ISR: "IL",
    ITA: "IT",
    JAM: "JM",
    JPN: "JP",
    JEY: "JE",
    JOR: "JO",
    KAZ: "KZ",
    KEN: "KE",
    KIR: "KI",
    PRK: "KP",
    KOR: "KR",
    KWT: "KW",
    KGZ: "KG",
    LAO: "LA",
    LVA: "LV",
    LBN: "LB",
    LSO: "LS",
    LBR: "LR",
    LBY: "LY",
    LIE: "LI",
    LTU: "LT",
    LUX: "LU",
    MAC: "MO",
    MDG: "MG",
    MWI: "MW",
    MYS: "MY",
    MDV: "MV",
    MLI: "ML",
    MLT: "MT",
    MHL: "MH",
    MTQ: "MQ",
    MRT: "MR",
    MUS: "MU",
    MYT: "YT",
    MEX: "MX",
    FSM: "FM",
    MDA: "MD",
    MCO: "MC",
    MNG: "MN",
    MNE: "ME",
    MSR: "MS",
    MAR: "MA",
    MOZ: "MZ",
    MMR: "MM",
    NAM: "NA",
    NRU: "NR",
    NPL: "NP",
    NLD: "NL",
    NCL: "NC",
    NZL: "NZ",
    NIC: "NI",
    NER: "NE",
    NGA: "NG",
    NIU: "NU",
    NFK: "NF",
    MNP: "MP",
    NOR: "NO",
    OMN: "OM",
    PAK: "PK",
    PLW: "PW",
    PSE: "PS",
    PAN: "PA",
    PNG: "PG",
    PRY: "PY",
    PER: "PE",
    PHL: "PH",
    PCN: "PN",
    POL: "PL",
    PRT: "PT",
    PRI: "PR",
    QAT: "QA",
    MKD: "MK",
    ROU: "RO",
    RUS: "RU",
    RWA: "RW",
    REU: "RE",
    BLM: "BL",
    SHN: "SH",
    KNA: "KN",
    LCA: "LC",
    MAF: "MF",
    SPM: "PM",
    VCT: "VC",
    WSM: "WS",
    SMR: "SM",
    STP: "ST",
    SAU: "SA",
    SEN: "SN",
    SRB: "RS",
    SYC: "SC",
    SLE: "SL",
    SGP: "SG",
    SXM: "SX",
    SVK: "SK",
    SVN: "SI",
    SLB: "SB",
    SOM: "SO",
    ZAF: "ZA",
    SGS: "GS",
    SSD: "SS",
    ESP: "ES",
    LKA: "LK",
    SDN: "SD",
    SUR: "SR",
    SJM: "SJ",
    SWE: "SE",
    CHE: "CH",
    SYR: "SY",
    TWN: "TW",
    TJK: "TJ",
    TZA: "TZ",
    THA: "TH",
    TLS: "TL",
    TGO: "TG",
    TKL: "TK",
    TON: "TO",
    TTO: "TT",
    TUN: "TN",
    TUR: "TR",
    TKM: "TM",
    TCA: "TC",
    TUV: "TV",
    UGA: "UG",
    UKR: "UA",
    ARE: "AE",
    GBR: "GB",
    UMI: "UM",
    USA: "US",
    URY: "UY",
    UZB: "UZ",
    VUT: "VU",
    VEN: "VE",
    VNM: "VN",
    VGB: "VG",
    VIR: "VI",
    WLF: "WF",
    ESH: "EH",
    YEM: "YE",
    ZMB: "ZM",
    ZWE: "ZW",
    ALA: "AX"
};

export const regionNameTo2 = {
    ES: {
        "a aoruña": "C",
        "albacete": "AB",
        "alicante/alacant": "A",
        "almería": "AL",
        "alava": "VI",
        "asturias": "O",
        "avila": "AV",
        "badajoz": "BA",
        "balears (baleares)": "PM",
        "barcelona": "B",
        "bizkaia": "BI",
        "burgos": "BU",
        "caceres": "CC",
        "cadiz": "CA",
        "cantabria": "S",
        "castellon/castello": "CS",
        "ciudad real": "CR",
        "cordoba": "CO",
        "cuenca": "CU",
        "gipuzkoa": "SS",
        "girona (gerona)": "GI",
        "granada": "GR",
        "guadalajara": "GU",
        "huelva": "H",
        "huesca": "HU",
        "jaen": "J",
        "la rioja": "LO",
        "las palmas": "GC",
        "leon": "LE",
        "lleida (lerida)": "L",
        "lugo": "LU",
        "madrid": "M",
        "malaga": "MA",
        "murcia": "MU",
        "navarra/nafarroa": "NA",
        "ourense (orense)": "OR",
        "palencia": "P",
        "Pontevedra": "PO",
        "salamanca": "SA",
        "santa cruz de tenerife": "TF",
        "segovia": "SG",
        "sevilla": "SE",
        "soria": "SO",
        "tarragona": "T",
        "teruel": "TE",
        "toledo": "TO",
        "valencia/valencia": "V",
        "valladolid": "VA",
        "zamora": "ZA",
        "zaragoza": "Z",
        "ceuta": "CE",
        "melilla": "ML"
    }
};

export const fullNameTo2 = {
    'Afganistán': 'AF',
    'Albania': 'AL',
    'Argelia': 'DZ',
    'Samoa Americana': 'AS',
    'Andorra': 'AD',
    'Angola': 'AO',
    'Anguila': 'AI',
    'Antártida': 'AQ',
    'Antigua y Barbuda': 'AG',
    'Argentina': 'AR',
    'Armenia': 'AM',
    'Aruba': 'AW',
    'Australia': 'AU',
    'Austria': 'AT',
    'Azerbaiyán': 'AZ',
    'Bahamas': 'BS',
    'Baréin': 'BH',
    'Bangladés': 'BD',
    'Barbados': 'BB',
    'Bielorrusia': 'BY',
    'Bélgica': 'BE',
    'Belice': 'BZ',
    'Benín': 'BJ',
    'Bermudas': 'BM',
    'Bután': 'BT',
    'Bolivia': 'BO',
    'Bosnia y Herzegovina': 'BA',
    'Botsuana': 'BW',
    'Isla Bouvet': 'BV',
    'Brasil': 'BR',
    'Territorio Británico del Océano Índico': 'IO',
    'Brunéi': 'BN',
    'Bulgaria': 'BG',
    'Burkina Faso': 'BF',
    'Burundi': 'BI',
    'Cabo Verde': 'CV',
    'Camboya': 'KH',
    'Camerún': 'CM',
    'Canadá': 'CA',
    'Islas Caimán': 'KY',
    'República Centroafricana': 'CF',
    'Chad': 'TD',
    'Chile': 'CL',
    'China': 'CN',
    'Isla de Navidad': 'CX',
    'Islas Cocos': 'CC',
    'Colombia': 'CO',
    'Comoras': 'KM',
    'República Democrática del Congo': 'CD',
    'Congo': 'CG',
    'Islas Cook': 'CK',
    'Costa Rica': 'CR',
    'Croacia': 'HR',
    'Cuba': 'CU',
    'Curazao': 'CW',
    'Chipre': 'CY',
    'República Checa': 'CZ',
    'Costa de Marfil': 'CI',
    'Dinamarca': 'DK',
    'Yibuti': 'DJ',
    'Dominica': 'DM',
    'República Dominicana': 'DO',
    'Ecuador': 'EC',
    'Egipto': 'EG',
    'El Salvador': 'SV',
    'Guinea Ecuatorial': 'GQ',
    'Eritrea': 'ER',
    'Estonia': 'EE',
    'Etiopía': 'ET',
    'Islas Malvinas': 'FK',
    'Islas Feroe': 'FO',
    'Fiyi': 'FJ',
    'Finlandia': 'FI',
    'Francia': 'FR',
    'Guayana Francesa': 'GF',
    'Polinesia Francesa': 'PF',
    'Territorios Australes Franceses': 'TF',
    'Gabón': 'GA',
    'Gambia': 'GM',
    'Georgia': 'GE',
    'Alemania': 'DE',
    'Ghana': 'GH',
    'Gibraltar': 'GI',
    'Grecia': 'GR',
    'Groenlandia': 'GL',
    'Granada': 'GD',
    'Guadalupe': 'GP',
    'Guam': 'GU',
    'Guatemala': 'GT',
    'Guernsey': 'GG',
    'Guinea': 'GN',
    'Guinea-Bisáu': 'GW',
    'Guyana': 'GY',
    'Haití': 'HT',
    'Islas Heard y McDonald': 'HM',
    'Santa Sede': 'VA',
    'Honduras': 'HN',
    'Hong Kong': 'HK',
    'Hungría': 'HU',
    'Islandia': 'IS',
    'India': 'IN',
    'Indonesia': 'ID',
    'Irán': 'IR',
    'Iraq': 'IQ',
    'Irlanda': 'IE',
    'Isla de Man': 'IM',
    'Israel': 'IL',
    'Italia': 'IT',
    'Jamaica': 'JM',
    'Japón': 'JP',
    'Jersey': 'JE',
    'Jordania': 'JO',
    'Kazajistán': 'KZ',
    'Kenia': 'KE',
    'Kiribati': 'KI',
    'Corea del Norte': 'KP',
    'Corea del Sur': 'KR',
    'Kuwait': 'KW',
    'Kirguistán': 'KG',
    'Laos': 'LA',
    'Letonia': 'LV',
    'Líbano': 'LB',
    'Lesoto': 'LS',
    'Liberia': 'LR',
    'Libia': 'LY',
    'Liechtenstein': 'LI',
    'Lituania': 'LT',
    'Luxemburgo': 'LU',
    'Macao': 'MO',
    'Macedonia del Norte': 'MK',
    'Madagascar': 'MG',
    'Malaui': 'MW',
    'Malasia': 'MY',
    'Maldivas': 'MV',
    'Mali': 'ML',
    'Malta': 'MT',
    'Islas Marshall': 'MH',
    'Martinica': 'MQ',
    'Mauritania': 'MR',
    'Mauricio': 'MU',
    'Mayotte': 'YT',
    'México': 'MX',
    'Micronesia': 'FM',
    'Moldavia': 'MD',
    'Mónaco': 'MC',
    'Mongolia': 'MN',
    'Montenegro': 'ME',
    'Montserrat': 'MS',
    'Marruecos': 'MA',
    'Mozambique': 'MZ',
    'Myanmar': 'MM',
    'Namibia': 'NA',
    'Nauru': 'NR',
    'Nepal': 'NP',
    'Países Bajos': 'NL',
    'Nueva Caledonia': 'NC',
    'Nueva Zelanda': 'NZ',
    'Nicaragua': 'NI',
    'Níger': 'NE',
    'Nigeria': 'NG',
    'Niue': 'NU',
    'Isla Norfolk': 'NF',
    'Islas Marianas del Norte': 'MP',
    'Noruega': 'NO',
    'Omán': 'OM',
    'Pakistán': 'PK',
    'Palaos': 'PW',
    'Palestina': 'PS',
    'Panamá': 'PA',
    'Papúa Nueva Guinea': 'PG',
    'Paraguay': 'PY',
    'Perú': 'PE',
    'Filipinas': 'PH',
    'Pitcairn': 'PN',
    'Polonia': 'PL',
    'Portugal': 'PT',
    'Puerto Rico': 'PR',
    'Catar': 'QA',
    'Rumanía': 'RO',
    'Rusia': 'RU',
    'Ruanda': 'RW',
    'Reunión': 'RE',
    'San Bartolomé': 'BL',
    'Santa Elena': 'SH',
    'San Cristóbal y Nieves': 'KN',
    'Santa Lucía': 'LC',
    'San Martín': 'MF',
    'San Pedro y Miquelón': 'PM',
    'San Vicente y las Granadinas': 'VC',
    'Samoa': 'WS',
    'San Marino': 'SM',
    'Santo Tomé y Príncipe': 'ST',
    'Arabia Saudita': 'SA',
    'Senegal': 'SN',
    'Serbia': 'RS',
    'Seychelles': 'SC',
    'Sierra Leona': 'SL',
    'Singapur': 'SG',
    'San Martín': 'SX',
    'Eslovaquia': 'SK',
    'Eslovenia': 'SI',
    'Islas Salomón': 'SB',
    'Somalia': 'SO',
    'Sudáfrica': 'ZA',
    'Georgia del Sur y las Islas Sandwich del Sur': 'GS',
    'Sudán del Sur': 'SS',
    'España': 'ES',
    'Sri Lanka': 'LK',
    'Sudán': 'SD',
    'Surinam': 'SR',
    'Svalbard y Jan Mayen': 'SJ',
    'Suecia': 'SE',
    'Suiza': 'CH',
    'Siria': 'SY',
    'Taiwán': 'TW',
    'Tayikistán': 'TJ',
    'Tanzania': 'TZ',
    'Tailandia': 'TH',
    'Timor Oriental': 'TL',
    'Togo': 'TG',
    'Tokelau': 'TK',
    'Tonga': 'TO',
    'Trinidad y Tobago': 'TT',
    'Túnez': 'TN',
    'Turquía': 'TR',
    'Turkmenistán': 'TM',
    'Islas Turcas y Caicos': 'TC',
    'Tuvalu': 'TV',
    'Uganda': 'UG',
    'Ucrania': 'UA',
    'Emiratos Árabes Unidos': 'AE',
    'Reino Unido': 'GB',
    'Estados Unidos': 'US',
    'Uruguay': 'UY',
    'Uzbekistán': 'UZ',
    'Vanuatu': 'VU',
    'Venezuela': 'VE',
    'Vietnam': 'VN',
    'Islas Vírgenes Británicas': 'VG',
    'Islas Vírgenes de los Estados Unidos': 'VI',
    'Wallis y Futuna': 'WF',
    'Sahara Occidental': 'EH',
    'Yemen': 'YE',
    'Zambia': 'ZM',
    'Zimbabue': 'ZW',
    'Islas Åland': 'AX'
};