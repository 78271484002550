import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './InformationPagesMenu.module.css';

const InformationPagesIcon = (props) => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootInformationMenu, className);

  return (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" id="globe" className={classes}>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#4d4d52" stroke="none">
      <path d="M244 4471 c-94 -23 -177 -91 -217 -180 -32 -68 -31 -189 2 -255 32 -65 90 -122 154 -152 l52 -24 2325 0 2325 0 52 24 c64 30 122 87 154 152 33 66 34 187 2 255 -30 66 -86 124 -151 156 l-57 28 -2305 2 c-1268 0 -2319 -2 -2336 -6z"/>
      <path d="M230 2861 c-84 -26 -160 -90 -201 -171 -19 -37 -23 -61 -23 -130 0 -72 4 -92 26 -135 31 -60 86 -114 148 -146 l45 -24 2335 0 2335 0 50 27 c111 59 170 155 170 278 0 123 -59 219 -170 278 l-50 27 -2320 2 c-1276 1 -2331 -2 -2345 -6z"/>
      <path d="M169 1227 c-232 -120 -221 -439 20 -559 l46 -23 2325 0 2325 0 57 28 c65 32 121 90 151 156 32 68 31 189 -2 255 -32 65 -90 122 -154 152 l-52 24 -2327 0 -2326 0 -63 -33z"/>
    </g>
  </svg>
  );
};

InformationPagesIcon.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default InformationPagesIcon;
