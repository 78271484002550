import React from 'react';

import css from './LanguageMenu.module.css'
import LanguageIcon from './LanguageIcon';
import { locales } from '../../../translations/translations'
import { FormattedMessage } from '../../../util/reactIntl';
import Menu from '../../Menu/Menu';
import MenuLabel from '../../MenuLabel/MenuLabel';
import MenuContent from '../../MenuContent/MenuContent';
import MenuItem from '../../MenuItem/MenuItem';

const LanguageMenu = (props) => (
    <Menu>
        <MenuLabel className={css.languageMenuLabel}>
            <LanguageIcon />
        </MenuLabel>
        <MenuContent className={css.languageMenuContent}>
            {locales.map(locale => (
                locale == props.currentLanguage ? (
                    <MenuItem key={`${locale}Item`} className={css.languageMenuItemSelected}>
                        <div><FormattedMessage id={`LanguageMenu.${locale}`} /></div>
                    </MenuItem>
                ) : (
                    <MenuItem key={`${locale}Item`} className={css.languageMenuItem}>
                        <div onClick={() => props.onSelect(locale)}><FormattedMessage id={`LanguageMenu.${locale}`} /></div>
                    </MenuItem>
                )))}
        </MenuContent>
    </Menu>);

export default LanguageMenu;