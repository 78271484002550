/**
 * A text field with phone number formatting. By default uses formatting
 * rules defined in the fiFormatter.js file. To change the formatting
 * provide alternative implementations for the format and parse functions
 * that are passed to the input field.
 */
import React, { useRef } from 'react';
import { injectIntl } from '../../util/reactIntl';
import { FieldTextInput } from '../../components';
import { formatIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { compose } from 'redux';

const FieldPhoneNumberInputComponent = props => {
  const { intl, format, parse, validate, ...inputProps } = props;

  const parsePhoneNumber = value => {
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber ? phoneNumber.formatInternational() : value;
  };

  const formatPhoneNumber = value => {
    if (typeof value !== 'string') {
      value = '';
    }
    return formatIncompletePhoneNumber(value === '' || value?.charAt(0) === '+' ? value : value);
  };

  const validatePhoneNumber = value => {
    const phoneNumber = typeof value === 'string' ? parsePhoneNumberFromString(value) : null;
    return phoneNumber && phoneNumber.isValid()
      ? undefined
      : intl.formatMessage({
          id: 'FieldPhoneNumberInput.phoneInvalidFormat',
        });
  };

  const inputPropsWithPhoneNumber = {
    ...inputProps,
    type: 'tel',
    // format: formatPhoneNumber,
    // parse: parsePhoneNumber,
    // validate: validatePhoneNumber,
  };

  return <FieldTextInput {...inputPropsWithPhoneNumber} />;
};
const FieldPhoneNumberInput = compose(injectIntl)(FieldPhoneNumberInputComponent);

FieldPhoneNumberInput.displayName = 'FieldPhoneNumberInput';

export default FieldPhoneNumberInput;
