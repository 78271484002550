import React from 'react';
import './button.css';
const BlackButton = ({ Text, onClick }) => {
  return (
    <button
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick()
        }
      }}
      id="modern_black_button"
    >
      {Text}
    </button>
  );
};

export default BlackButton;
