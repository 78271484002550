import React, { useState } from 'react';
import styles from './HeaderMenueWarningBillingAddress.module.css'; // Importing CSS Module
import { FormattedMessage } from 'react-intl';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import basedPostUrlRequestLogedIn from '../../util/apiRequests/basedPostUrlRequestLogedIn';

const HeaderMenueWarningBillingAddress = ({ currentUser, message }) => {
  const [incompleteBookingRequest, setIncompleteBookingRequest] = useState(
    currentUser?.attributes?.profile?.metadata?.incompleteBookingRequest?.isRequest
  );

  const handleClose = async () => {
    basedPostUrlRequestLogedIn('/api/confirme-provider-billing-address/' + currentUser?.id?.uuid, {
      userId: currentUser?.id?.uuid,
      closeBanner: true,
    })
      .then(res => {
        setIncompleteBookingRequest(false);
      })
      .catch(err => {
        setIncompleteBookingRequest(false);
      });
  };
  return (
    <>
      {incompleteBookingRequest && (
        <div className={styles.HeaderMenueWarningBillingAddress}>
          <div className={styles.containerBar}>
            <div className={styles.warningMessage}>
              <FormattedMessage id={`Confirm.${message}`} />
            </div>
            <button onClick={handleClose} className={styles.close_button}>
              <IoIosCloseCircleOutline className={styles.svg} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderMenueWarningBillingAddress;
