import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.1011 1.3961C9.5777 0.77363 10.1669 0.25 11 0.25C11.8331 0.25 12.4223 0.77363 12.899 1.3961C13.3674 2.00773 13.864 2.89876 14.471 3.98776L14.8296 4.63106C15.2222 5.33523 15.3226 5.48482 15.4486 5.58044C15.5698 5.67247 15.7262 5.7238 16.4896 5.89654L17.1897 6.05492C18.3653 6.32088 19.3338 6.53999 20.0392 6.81796C20.7714 7.10651 21.4121 7.5318 21.6588 8.32502C21.9029 9.1101 21.6285 9.8323 21.2045 10.5059C20.7925 11.1604 20.1344 11.9298 19.3306 12.8698L18.8561 13.4247C18.3391 14.0292 18.2311 14.1772 18.1803 14.3404C18.1286 14.5069 18.1334 14.6992 18.2115 15.5052L18.2831 16.2433C18.4048 17.4994 18.5041 18.5236 18.4693 19.3037C18.434 20.0977 18.2536 20.8601 17.5984 21.3576C16.9308 21.8643 16.1542 21.8072 15.4044 21.5924C14.6774 21.3841 13.7711 20.9667 12.6705 20.46L12.0149 20.1581C11.2975 19.8278 11.1439 19.7748 11 19.7748C10.8561 19.7748 10.7025 19.8278 9.9852 20.1581L9.3295 20.46C8.22898 20.9667 7.32265 21.3841 6.59565 21.5924C5.84587 21.8072 5.0692 21.8643 4.40168 21.3576C3.7464 20.8601 3.56607 20.0977 3.5307 19.3037C3.49595 18.5236 3.59523 17.4993 3.71697 16.2433L3.7885 15.5052C3.8666 14.6992 3.87147 14.5069 3.81971 14.3404C3.76894 14.1772 3.66094 14.0292 3.14393 13.4247L2.66945 12.8698C1.8656 11.9299 1.20753 11.1604 0.795528 10.5059C0.371488 9.8323 0.0971378 9.1101 0.341268 8.32502C0.587938 7.5318 1.22867 7.10651 1.96086 6.81796C2.66622 6.53999 3.63474 6.32088 4.81038 6.05492L4.87404 6.04052L5.51039 5.89654C6.27382 5.72381 6.43023 5.67247 6.55148 5.58044C6.67743 5.48482 6.77785 5.33523 7.17039 4.63106L7.52899 3.98775C8.13601 2.89876 8.63268 2.00773 9.1011 1.3961Z" />
    </svg>
  );
};

IconReviewStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
