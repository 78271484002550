import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Button,
  IconAdd,
  NotificationBadge,
} from '../../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import {
  AccountSettingsIcon,
  BusinessIcon,
  FavoritesIcon,
  MyEquipmentIcon,
  NotificationsIcon,
  ProfileSettingsIcon,
} from '../TopbarSearchForm/TopbarIcons';

const MenuItems = params => {
  const { currentUser, notificationCount, currentPage, stripeAccount, onLogout } = params;
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;
  const stripeAccountData = stripeAccount?.attributes?.stripeAccountData;
  const payouts_enabled = stripeAccountData?.payouts_enabled;
  if (payouts_enabled) {
    return (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          {notificationDot}
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="InboxPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: 'all' }}
            >
              <NotificationsIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.notificationsLink" />
              {notificationCountBadge}
            </NamedLink>
          </MenuItem>
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <MyEquipmentIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="FavoriteListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('FavoriteListingsPage'))}
              name="FavoriteListingsPage"
            >
              <FavoritesIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.favoriteListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <ProfileSettingsIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <AccountSettingsIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="MyBusinessPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('MyBusinessPage'))}
              name="MyBusinessPage"
            >
              <BusinessIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.accountMyBusinessLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  } else {
    return (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          {notificationDot}
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="InboxPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: 'all' }}
            >
              <NotificationsIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.notificationsLink" />
              {notificationCountBadge}
            </NamedLink>
          </MenuItem>
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <MyEquipmentIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="FavoriteListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('FavoriteListingsPage'))}
              name="FavoriteListingsPage"
            >
              <FavoritesIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.favoriteListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <ProfileSettingsIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <AccountSettingsIcon className={css.profileMenuIcon} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }
};
export default MenuItems;
