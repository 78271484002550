import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.css';
import NamedLink from '../../NamedLink/NamedLink';
import BlackButton from '../buttons/BlackButton';
import WhiteButton from '../buttons/WhiteButton';

const CookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useState(true);

  // Function to handle accepting cookies
  const acceptCookies = () => {
    setCookieConsent(true);
    Cookies.set('myCookieConsent', true, { expires: 365 }); // Set cookie with expiry of 365 days
  };

  // Function to handle rejecting cookies
  const rejectCookies = () => {
    setCookieConsent(true);
    Cookies.set('myCookieConsent', false, { expires: 365 });
  };

  useEffect(() => {
    const consentCookie = Cookies.get('myCookieConsent');
    if (consentCookie === 'true' || consentCookie === 'false') {
      setCookieConsent(true);
    } else {
      setCookieConsent(false);
    }
  }, []); // Run this effect only once on component mount
  return (
    <>
      {!cookieConsent && (
        <div id="" className="cookie-consent">
          <h5 id="cookie_title">Cookies</h5>
          <h5 id="cookie_text">
            Usamos cookies para asegurar que te damos la mejor experiencia en nuestra web.
          </h5>
          <h5 id="cookie_text">
            Si continúas usando este sitio, asumiremos que estás de acuerdo con ello.
          </h5>
          <h5 id="cookie_text">
            Más información en nuestra
            <NamedLink className={''} name="CookiePolicyPage">
              {' '}
              Política de cookies
            </NamedLink>
          </h5>
          <div className="buttons_container">
            <WhiteButton onClick={rejectCookies} Text={'Rechazar cookies'} />{' '}
            <BlackButton onClick={acceptCookies} Text={'Aceptar cookies'} />
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
